import React from "react";
import Header from "../components/header/header.js";

const Riccione = () => {
  return (
    <>
      <Header
        title="La città di Riccione"
        logo="riccione.png"
        metaDescription="Per la Città di Riccione ACME ha realizzato l’infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              Committente: Comune di Riccione
              <br /> Anno: 2016 - 2022
            </h2>
            <p className="description">
              <br />
              <br />
              Riccione diventa la prima città interamente cablata grazie ad una
              rete WiFi gratuita che copre tutto il lungomare e le principali
              vie interne della movida (V.le Ceccarini e V.le Dante). Il tutto
              per una copertura totale del servizio lunga 6 km.
              <br />
              <br />
              Non più isole WiFi con molteplici access point, ma un’unica area
              nella quale spostarsi senza perdita di segnale, grazie al
              cablaggio di apparati performanti. L’erogazione continua del
              servizio wireless si estende da nord a sud sul lungomare ed è
              fruibile senza perdita di connessione o richiesta di ulteriori
              autenticazioni. L’accesso alla navigazione si realizza attraverso
              il sistema Connect@You di proprietà della ACME Produzioni Srl, un
              gateway che permette l’autenticazione alla rete attraverso
              differenti modalità.
            </p>
            <div className="section" />
          </div>
        </div>
        <div className="columns">
          <div className="column is-7">
            <p className="description">
              Esigenze del cliente
              <br />• Realizzare una rete WiFi per la copertura di tutto il
              lungomare e le principali vie interne
              <br /> • Rendere estremamente semplice e veloce la procedura di
              autenticazione per gli utenti
              <br /> • Analisi della tipologia di utenti che frequentano la
              città di Riccione
            </p>
            <div className="section" />
          </div>
        </div>
        <div className="columns">
          <div className="column is-7">
            <p className="description">
              Le nostre soluzioni
              <br />• Realizzazione infrastruttura rete WiFi per gli utenti
              <br />• Captive portal Connetc@You per autenticazione degli utenti
              <br /> • Attivazione del servizio form dinamico per profilazione
              target utenti
              <br /> • Fornitura connettività dedicata (n. 5 punti di
              distribuzione segnali)
              <br /> • Servizio di assistenza
            </p>
            <div className="section" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Riccione;
